html,
body {
  color: #555555;
  background-color: #fff;
}
.alert {
  color: red;
}
.infodot {
  position: absolute;
  margin: -45px 0px 0px -10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  font-size: 0.8em;
  padding: 3px 7px 0px 8px;
  font-weight: bold;
}
.zzz {
  border: 1px solid red;
}
.userDetails {
  padding: 20px 0px 30px 0px;
  background-color: #fff;
}
.primaryBackground {
  background-color: #009de6 !important;
}
.primaryBackground:hover {
  color: #fff !important;
  background-color: #12b4ff !important;
}
.greenBackground {
  background-color: #6db041 !important;
}
.greenBackground:hover {
  background-color: #64a837 !important;
}
.nrbackground {
  border: 1px solid #ffffff;
  font-size: 1em;
}
.nrbackground:hover {
  border: 1px solid #bee3f8;
}
.greenBackground:hover {
  color: #fff !important;
  background-color: #6db041 !important;
}
.title {
  font-size: 1.4em;
}
.userColor {
  color: #ffffff !important;
}
.userColor:hover {
  color: #eeeeee !important;
}
.primaryColor {
  color: #009de6 !important;
}
.primaryColor:hover {
  color: #12b4ff !important;
}
.drawerHeader {
  position: relative;
  width: 10px;
  margin-bottom: 20px;
}
.primaryBorderColor {
  border-color: #009de6 !important;
}
.primaryBorderColor:hover {
  border-color: #12b4ff !important;
}
.smsswitch {
  position: relative;
  z-index: 1;
}
.logo {
  position: relative;
  background-image: url("images/warsztappc.png");
  background-size: 50px;
  background-position: px 0px;
  background-repeat: no-repeat;
  height: 55px;
  min-width: 50px;
}
.datepicker {
  position: relative;
  font-size: 0.9em;
  width: 130px;
  z-index: 100000 !important;
}
.datepicker input {
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 0.9em;
  width: 100px;
  z-index: 100000 !important;
}
.pageHeader {
  height: 55px;
  width: auto;
  border-radius: 5px;
  cursor: pointer;
  cursor: hand;
}
.warsztApp {
  padding: 10px 0px 10px 0px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: left;
}
.warsztAppTitle {
  font-weight: bold;
  font-size: 0.9em;
  width: auto;
  color: #32a8c0;
}
.garageName {
  padding: 0px 0px 5px 1px;
  position: relative;
  width: 100%;
  text-align: start;
}
.card {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}
.infoHeader {
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #efefef;
  padding: 0px 15px 5px 15px;
}
.infoHeader:hover {
  background-color: #fafafa;
}
.servicesDetailsTable td {
  font-size: 0.9em;
  color: #555;
  /*  border-right: 1px solid #efefef !important; */
}
.white {
  color: white;
}
.graybg {
  background-color: #fafafa !important;
}
.tox .tox-menu.tox-collection.tox-collection--list.tox-selected-menu {
  z-index: 100000 !important;
}
.center {
  align-items: center !important;
  align-content: center !important;
  align-tracks: center !important;
  text-align: center !important;
}
h2 {
  margin-bottom: 15px;
  border: dashed 1px #eaeaea;
  border-radius: 5px;
  padding: 10px;
}
h3 {
  margin-bottom: 15px;
  border-top: solid 1px #eaeaea;
  border-bottom: solid 1px #eaeaea;
  padding: 10px;
}
.thHour,
/* .thMinute {
  max-width: 20px;
} */
.proper {
  font-size: 0.9em;
}
.darkGray {
  color: #646262;
}
.regular {
  size: 0.9em;
}
.small {
  font-size: 0.9em;
}
.big {
  font-size: 1.4em;
}
.scrollX {
  overflow-x: auto;
}
.scrollY {
  overflow-y: auto;
}
.pabsolute {
  position: absolute;
}
.prelative {
  position: relative;
  height: auto;
}
.left20 {
  margin-left: 20px;
}

.w100 {
  position: relative;
  width: 100%;
}

.left {
  text-align: left !important;
}

.h100 {
  height: 100%;
}

.link {
  text-decoration: underline;
  color: #4caaad !important;
}
.link:hover {
  text-decoration: none;
  color: #2ababe !important;
}

.weblink {
  color: #12b4ff;
  text-decoration: underline !important;
  cursor: pointer;
  cursor: hand;
}
.weblink:hover {
  color: hsl(199, 71%, 34%);
}

.darkgray {
  color: #555;
}

.userRow {
  border: "1px solid #c4e9f5";
  cursor: pointer;
  cursor: hand;
}

.userRow:hover {
  background-color: "#c4e9f5";
}

.gray {
  color: #afafaf;
}
.green {
  color: hsl(93, 75%, 46%);
}
.bgwhite {
  background-color: #ffffff;
}
.serviceTr Td {
  border-left: 1px dashed #eee;
  padding: 15px 15px;
}
.datePicker {
  border: 1px solid rgb(233, 233, 233);
  border-radius: 6px;
  width: 150px;
  text-align: center;
  font-size: 1.2em;
  color: rgb(90, 179, 218);
  font-weight: bold;
  padding: 5px 15px;
  cursor: pointer;
  cursor: hand;
}
.nearestTd {
  color: #292929;
  padding: 6px 3px !important;
}
.datePicker:hover {
  border: 1px solid rgb(99, 186, 230);
}
.hour div {
  margin-left: -20px;
  height: inherit;
  background-color: #ffffff;
}
.servicehour {
  border-left: none !important;
}
.hour {
  color: rgb(214, 214, 214);
  font-size: 1.8em;
  border-width: 1px;
  border-color: #000000;
  border-style: dashed;
  width: fit-content;
  /* 
  font-family: Sans-Serif;
  text-align: center; */
}
.minute {
  padding: 10px 0px;
  /* max-width: 20px; */
  border-width: 1px;
  border-color: #000000;
  border-style: dashed;
  font-family: Sans-Serif;
  text-align: center;
}
.bold {
  font-weight: bold;
}
.medium {
  font-size: 1.5em;
}
TableContainer {
  border-radius: 10;
  border: 1px solid rgb(228, 228, 228);
}
.menu_wrapper {
  position: relative;
  display: block;
  min-width: 64px !important;
  color: #3598c5;
  margin: 0 auto !important;
  padding: 0px !important;
}
.menu_button {
  position: relative;
  display: block;
  color: #3598c5;
  margin: 0 auto !important;
  padding: 0px !important;
  border: 1px solid rgb(81, 255, 0);
}
.upper {
  text-transform: uppercase;
}
.services_details {
  height: 100%;
  display: table-cell;
  border: 1px solid red;
  position: relative;
  top: 0px;
  padding: 0 auto;
  margin: 0px;
  text-align: start !important;
}
.selected {
  color: #4299e1 !important;
}
.menu {
  color: #bdbdbd;
  position: relative;
  display: block;
  top: -5px !important;
  margin: 0 auto !important;
  padding: 0px !important;
  text-transform: uppercase;
  font-size: 0.7em;
}
Table {
  color: rgb(83, 83, 83);
  table-layout: auto !important;
  text-align: center;
}
.publicAppointment {
  margin: 0 auto;
  font-size: 0.8em;
  padding: 5px 15px;
}
.itemsBox {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  max-width: 1000px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.vehiclesBox {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}
.fixedTable {
  position: relative;
  max-height: 70vh;
  max-width: 90vw;
  overflow: auto;
  overflow-y: scroll;
}
.fixedTable tr:last-child td {
  border-bottom: none !important;
}
.rawItemsClass {
  overflow: hidden;
}
.pageTop {
  background-color: rgb(255, 255, 255);
  margin: 0 auto;
  padding: 0;
  position: relative;
}
.topMenuWrapper {
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  padding: 0px;
  text-align: center;
  width: 100vw;
  overflow-x: auto;
}

.infoRow {
  position: relative;
  border-color: #fff;
  border-width: 0px;
  border-style: solid;
  margin: 1px 0px 1px 0px;
  padding: 5px 5px 5px 10px;
  background-color: #fafafa;
  border-radius: 4px;
  font-size: 0.9em;
  color: #555555;
  cursor: pointer;
  cursor: hand;
}
.infoRow:hover {
  background-color: #fff;
}
.infoPopower {
  background-color: #fafafa;
  box-shadow: rgb(0, 0, 0, 0.15) 0px 9px 29px;
  border-radius: 5px;
}
.hand {
  cursor: pointer;
  cursor: hand;
}
.infoNew {
  font-weight: bold;
}

.infoRed {
  font-weight: 200;
}
.tel {
  color: #32a8c0;
}
.garagesListPage {
  background-color: #fff;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  position: relative;
  margin: 0 auto;
  padding: 0px;
  position: relative;
  height: 85vh;
  width: 100%;
}
.mapa {
  position: relative;
  width: 100%;
  height: 100%;
}
.triggerButton {
  transform: rotate(-90deg);
  position: fixed;
  padding: 40px 30px 10px 30px;
  color: #fff;
  border-radius: 0px 0px 5px 5px;
  top: 150px;
  left: -30px;
}
.garagesList {
  position: fixed;
  margin: 0 auto;
  padding: 10px 30px 100px 1%;
  width: auto;
  height: 100%;
  overflow-y: scroll;
}
.garagesListHeader {
  border-bottom: 1px solid#efefef;
}
.garageTd {
  padding: 6px 9px !important;
  /* border: none !important; */
}
/* .garageTd button{
  background-color: #fafafa !important;
  color: hsl(197, 100%, 50%);
} */
.garageCard {
  background-color: #fff;
  /* border: #efefef 1px solid; */
  padding: 0px;
  border-radius: 14px;
  margin: 30px 10px;
  color: #555;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.09);
}
.garageCard:hover {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.09);
}
.garageCard:hover .garageCardName {
  color: #32a8c0;
}
.garageCard:hover .avaliableComponentBox {
  border-color: hwb(190 73% 3%);
}
.garageCardName {
  position: relative;
  width: 100%;
  text-align: left;
  font-size: 0.9em;
  font-weight: 400;
  color: #838383;
  padding: 25px 25px 0px 25px;
  position: relative;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
.garageWrap {
  position: relative;
  margin: 0 auto;
  padding: 0px;
  display: table;
  width: 50%;
  height: 100%;
}
.datanavigationwraper {
  position: relative;
  margin: 0 auto;
  padding: 0px;
  display: block;
  width: 100%;
  text-align: center;
  /* border: 1px solid red; */
}
.garageCardBody {
  position: relative;
  display: table-cell !important;
  width: 100%;
  padding: 0px 20px 20px 20px;
  text-align: left;
}
@media only screen and (max-width: 600px) {
  .garageCardBody {
    display: block !important;
  }
}
.avaliableComponentBox {
  position: relative;
  margin-right: 15px;
  height: 100%;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border: 1px solid #efefef;
  border-radius: 10px;
}
.garageCardDateButton {
  position: relative;
  padding: 40% 0%;
}
.googlePictureWrap {
  overflow: hidden;
  border-radius: 50%;
  border: none !important;
}
.googlePictureWrap:hover {
  background: none !important;
}
.googlePicture {
  background-color: transparent;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid transparent;
}
.googlePicture:hover {
  background-color: hwb(194 85% 3%);
}
.googlelogincomponent {
  border-top: 1px solid #eeeeee;
  margin: 20px 0px;
  padding: 20px 0px;
}
.servicePopoverBody {
  position: relative;
  max-height: 200px;
  overflow-y: auto;
}
.buttonBigTxt {
  position: relative;
  /* padding: 30px 10px 10px 10px !important; */
}
.buttonBigTxt div:first-child {
  position: relative;
  display: block;
  font-weight: bold;
  /* margin: 10px 10px 0px 10px !important;
  padding: 10px 10px 0px 10px !important; */
  font-size: 1em;
}
.buttonBigTxt div:last-child {
  position: relative;
  display: block;
  font-weight: lighter;
  /* margin: 0px 10px 10px 10px !important;
  padding: 0px 10px 10px 10px !important; */
  font-size: 0.9em;
}
.buttonSmallTxt {
  display: block;
  /* margin: 0px 10px 10px 10px !important;
  padding: 0px 10px 10px 10px !important; */
  font-size: 0.8em;
}
.garageHour {
  position: relative;
  margin: 0px 20px 0px 0px;
  padding: 0px 20px 0px 0px;
}

.tox.tox-tinymce-aux {
  z-index: 4000;
}
.textwrap {
  direction: ltr;
  width: 10vw;
  margin: auto;
  text-align: justify;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.index0 {
  border-bottom: 1px solid #cedce9 !important;
}
.index1 {
  border-bottom: 1px dashed #d0d4d4 !important;
}
.rowspans {
  border-left: none !important;
}
/*
Td {
  color: #5e5252;
  background-color: #fff;
  border-width: 0 1px 0 0;
  border-style: dashed;
}

.completed {
  background-color: #e1ffd9;
}
.incomplete {
  background-color: #f0f0f0;
}
.loading {
  background-color: #5e5252;
}
.ready {
  background-color: #b82525;
} */
